<template>
  <div class="main clearfix">
    <div
      style=" text-align:center; overflow: auto"
    >
      <iframe
        :src="authorize"
        height="404"
        frameBorder="0"
        scrolling="false"
      />
  </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";

import axios from "@/api/apis";

export default {
  name: "Wechat",
  created() {},
  setup() {
    const state = reactive({
      authorize: ""
    });

    //从地址栏中获取两个参数code state
    const loginWechatCallback = () => {
      axios
        .post("/account/login/wechat/callback")
        .then(function(data) {
          //拿到token，拿到是否绑定电话号

          //是   跳转到 我的页面
          //否   跳转到 绑定页面

        })
        .catch(function(error) {
          console.log(error);
        });
    };

    onMounted(() => {
      loginWechatCallback();
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style scoped>
</style>
